
import { defineComponent, ref, onMounted, computed } from "vue";
import InvoiceService from "@/core/services/car/InvoiceService";
import { useStore } from "vuex";
import { useUpdateCustomerData } from '@/store/composable/Customer'

// import ViewDocumentsModal from "@/components/modals/forms/ViewDocumentsModal.vue";
// import UploadInvoiceDocument from "@/components/modals/forms/UploadInvoiceDocument.vue";
export default defineComponent({
  name: "invoice-action",
  // components: {  UploadInvoiceDocument },
  props: {
    invoice: Object,
    isRefund: {
      type: Boolean,
      required: false,
      default: false
    },
    activeLink: {
      type: String,
      required: false,
      default: ''
    },
    showInvoiceDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    showActions: {
      type: Boolean,
      required: false,
      default: true
    },
    showAmendInvoice: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup() {
    const store = useStore();
    const userPermissions = computed(() => {
      return store.getters.getUserPermissions;
    });
    let invoiceId = ref<string>("");

    const getPolicyDocuments = (invoice_id) => {
      InvoiceService.getInvoiceDocuments({
        invoice_id: invoice_id,
      }).then(() => {
        console.log("open");
      });
    };
    const uploadReceipt = async(invoice_id) => {
      const data = await useUpdateCustomerData({
        linkType: 'get-upload-receipt',
        invoice_id
      })
      if(data.status <= 299) {
        InvoiceService.setUploadReceiptDetails(data.data.data.invoice);
        InvoiceService.setModalInvoice(true);
      }
      // invoiceId.value = invoice_id;
      // console.log('invoice_id',invoiceId)
    };
    /**
     * return data
     *
     */
    const viewReceipt = (invoice) => {
      window.open(invoice.invoice_doc.file_path, "_blank");
    };
    const current = computed(() => {
      return store.getters.currentUser;
    });

    const initiateAmendRefund = (invoice_id) => {
      InvoiceService.initiateAmendRefund({invoice_id});
    };

    return {
      viewReceipt,
      getPolicyDocuments,
      userPermissions,
      uploadReceipt,
      invoiceId,
      current,
      initiateAmendRefund,
      // uploadFile,
      // formData
    };
  },
});
